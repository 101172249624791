import { useState, KeyboardEvent, useRef, useEffect } from "react";
import "./SearchInput.css";
import { useAppDispatch } from "../../app/store";
import { selectSearchSubjectsValue, setSearchSubjectsValue } from "../../reduxSlices/subjectsSlice";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { selectMobileFeatures } from "../../reduxSlices/globalFlagsSlice";

const SearchInput = () => {
  const [focus, setFocus] = useState(false);
  const dispatch = useAppDispatch();
  const searchValue = useSelector(selectSearchSubjectsValue);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const [activeSearch, setActiveSearch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      handleClearSearchAndInput()
    }
  }, [])

  const setSearchValue = async (value: string) => {
    await dispatch(setSearchSubjectsValue(value));
  };

  const handleFocus = (bool: boolean) => {
    setFocus(bool);
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClearSearchAndInput = () => {
    setSearchValue("");
    setActiveSearch(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleExpandSearch = () => {
    if (!mobileFeatures) return;
    if (activeSearch) {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
      console.log(inputRef.current?.focus());

      inputRef.current?.focus();
    }
  };
  return (
    <form
      onKeyDown={handleKeyDown}
      className={`search-container ${activeSearch ? "mobile-search-wrapper" : ""} ${focus ? "input-focus-border" : ""}`}
    >
      <SlIcon name="search" className="search-icons" onClick={handleExpandSearch}></SlIcon>
      <input
        className={`search-input ${mobileFeatures && activeSearch ? "mobile-search-active" : "mobile-search-hide"}`}
        type="text"
        ref={inputRef}
        value={searchValue}
        onChange={handleInputChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        placeholder={"Buscar..."}
      />
      {(searchValue?.length > 0 || activeSearch) && (
        <button className="clear-search" onClick={handleClearSearchAndInput}>
          <SlIcon name="x" className="search-icons"></SlIcon>
        </button>
      )}
    </form>
  );
};

export default SearchInput;
