import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlexCalendar from "../components/FlexCalendar/FlexCalendar";

const MobileCalendario = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", gap: "10px", paddingBottom: "70px" }}>
      <div className="mobile-calendario-box">
        <FlexCalendar/>
      </div>
    </div>
  );
};

export default MobileCalendario;
