import axios from "axios";
import { IReserveRequest } from "../types";
import { ISubjectQuotas } from "../reduxSlices/subjectsSlice";

export const reserveSection = async (reserveData: IReserveRequest) => {
  const { data, status } = await axios.post(`/reservar`, reserveData);
  return { data, status };
};

export const changeSection = async (reserveData: IReserveRequest) => {
  const { data, status } = await axios.post(`api/cambiarSeccion`, reserveData);
  return { data, status };
};

interface ICuposAlumnoResponse {
  error: boolean;
  errorMessage: string;
  idCurso: number;
  idCupo: number;
  cantidad: number;
  semaforo: number;
}

const toCupos = (data: ICuposAlumnoResponse[]): ISubjectQuotas => {
  return data.reduce((acc: any, e) => {
    return {
      ...acc,
      [e.idCurso]: { qty: e.cantidad, availability: e.semaforo, quotaId: e.idCupo },
    };
  }, {});
};

export const getCuposAlumno = async (idPersona: string) => {
  const { data, status } = await axios.post(`/reservar/getCuposAlumno?r=${Math.random().toString()}`, {
    idPersona,
  });
  const newData = toCupos(data);
  const response: ICuposAlumnoResponse[] = data;
  return { data: newData, status, response };
};

export const getCuposCurso = async (idCupoCurso: number) => {
  const { data, status } = await axios.post(`/reservar/getCuposCurso?r=${Math.random().toString()}`, {
    idCupoCurso,
  });
  const newData = toCupos([data]);
  return { data: newData, status };
};

export const getCuposCursoList = async (
  idCupoCursoLista: number[]
): Promise<{ data: ISubjectQuotas; status: number }> => {
  const { data, status } = await axios.post(`/reservar/getCuposCursoLista?r=${Math.random().toString()}`, {
    idCupoCursoLista,
  });
  const newData = toCupos(data);
  return { data: newData, status };
};
