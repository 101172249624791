import {
  selectFilterTagsList,
  selectSelectedFilterTagsList,
  setSelectedFilterTagsList,
} from "../../reduxSlices/subjectsSlice";
import "./PanelFilters.css";
import { useSelector } from "react-redux";
import "animate.css";
import { IoMdClose } from "react-icons/io";
import { FaSquareCheck } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/store";
import { setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import { BiSelectMultiple } from "react-icons/bi";
import { FaRegSquare } from "react-icons/fa";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const PanelFilters = () => {
  const dispatch = useAppDispatch();
  const filterTagsList = useSelector(selectFilterTagsList);
  const selectedFilterTagsListStore = useSelector(selectSelectedFilterTagsList);
  const [selectedTags, setSelectedTags] = useState<any[]>(selectedFilterTagsListStore || []);

  useEffect(() => {
    dispatch(setSelectedFilterTagsList(selectedTags));
  }, [selectedTags]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setPanelFilters(false));
  };

  const handleClickAll = () => {
    if (selectedFilterTagsListStore && selectedFilterTagsListStore.length === filterTagsList?.length) {
      setSelectedTags([]);
    } else {
      if (filterTagsList?.length) {
        setSelectedTags(filterTagsList);
      }
    }
  };

  const handleCheckTag = (tag: string) => {
    setSelectedTags((prevList: string[]) => {
      if (prevList.includes(tag)) {
        return prevList.filter((item) => item !== tag);
      } else {
        return [...prevList, tag];
      }
    });
  };

  return (
    <div className="panel-filters animate__animated animate__slideInRight animate__faster">
      {/* <p>{JSON.stringify(selectedFilterTagsListStore)}</p> */}
      <div className="panel-filters-title mx-[30px]">
        <BiSelectMultiple
          onClick={handleClickAll}
          className={`panel-filters-select-all ${
            selectedFilterTagsListStore?.length === filterTagsList?.length ? "panel-filters-select-all-active" : ""
          }`}
        />
        <h2 className="panel-drawer-title-name">Filtros</h2>
        <IoMdClose style={{ color: "black", height: "20px", cursor: "pointer" }} onClick={handleClose} />
      </div>
      <div className="bg-[#F5F5F5] py-2 px-[30px] font-bold text-[14px]">Campos menores / Orientaciones</div>

      <div className="px-[30px]">
        <div className="panel-filters-content">
          {filterTagsList?.map((item) => (
            <div key={item} className="panel-filter-item" onClick={() => handleCheckTag(item)}>
              {selectedTags.includes(item) ? (
                <SlIcon name="check-square-fill" className="panel-checkbox-active"></SlIcon>
              ) : (
                <SlIcon name="square" className="panel-checkbox"></SlIcon>
              )}
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PanelFilters;
