import React from "react";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import "./Modal.css";

interface IModalButton {
  label: string;
  buttonFn: () => void;
  color?: string;
}

interface IPropsModal {
  open: boolean;
  onClose: () => void;
  message?: string; // Texto simple
  messageHtml?: React.ReactNode; // JSX o HTML
  okButton?: IModalButton;
  closeButton?: IModalButton;
  subMessage?: string;
  topBorderWarn?: boolean;
}

const Modal = ({
  open,
  onClose,
  message,
  messageHtml,
  okButton,
  closeButton,
  subMessage,
  topBorderWarn,
}: IPropsModal) => {
  return (
    <SlDialog
      open={open}
      onSlAfterHide={onClose}
      className={`shoelace-modal ${topBorderWarn ? "warn-border" : "success-border"}`}
      noHeader
    >
      <div className="custom-modal">
        {/* Renderiza messageHtml si está presente, sino renderiza el texto plano */}
        {messageHtml ? (
          <div className="custom-modal-message">{messageHtml}</div>
        ) : (
          <p className="custom-modal-message">{message}</p>
        )}

        {subMessage && <p className="custom-modal-submessage">{subMessage}</p>}

        <div className="custom-modal-actions">
          {okButton && (
            <button
              slot="footer"
              className="custom-modal-actions-button-ok"
              onClick={okButton.buttonFn}
              style={{ border: `${okButton.color} solid 2px`, color: okButton.color }}
            >
              {okButton.label}
            </button>
          )}
          {closeButton && (
            <button
              slot="footer"
              className="custom-modal-actions-button-cancel"
              onClick={closeButton.buttonFn}
              style={{ background: closeButton.color }}
            >
              {closeButton.label}
            </button>
          )}
        </div>
      </div>
    </SlDialog>
  );
};

export default Modal;
