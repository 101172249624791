import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect, useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import { useDispatch, useSelector } from "react-redux";
import { selectInscribedSubjects, selectOfferedSubjects, setDetailSubjectOffered } from "../../reduxSlices/subjectsSlice";
import { IInscribedSubject } from "../../types";
import EmptyCourses from "../EmptyCourses/EmptyCourses";
import { setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import BtnUnsuscribeSubject from "../BtnUnsuscribeSubject/BtnUnsuscribeSubject";
import { findProfeTitular } from "../../utils";

const DesktopSelectedSubjects = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const [totalCreditos, setTotalcreditos] = useState(0);
  const offeredSubjects = useSelector(selectOfferedSubjects);

  useEffect(() => {
    setTotalcreditos(inscribedSubjects?.reduce((acc, current) => acc + current.sections[0].creditos, 0) || 0);
  }, [inscribedSubjects]);
  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleShowSubjectoInfo = (subjectId: number, cursoId: number) => {
    dispatch(setPanelFilters(false));
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
  };

  const renderSelectedSubjects = (subjects: IInscribedSubject[]) => {
    return subjects.map((s, index) => {
      const isLastItem = subjects.length === index + 1;
      const buscadoEnOferta = offeredSubjects?.find(subject=> Number(subject.id) ===s.id)?.sections.find(section=>section.id === s.idCurso)
      return (
        <div
          className="desktop-item-wrapper"
          key={s.title + index}
          style={!isLastItem ? { borderBottom: "0.5px solid #eeeeee" } : {}}
        >
          <div className="desktop-inscribed-subject-item">
            <div className="desktop-selected-subjects-item-left">
              <h2
                className="desktop-selected-subject-item-left-title"
                onClick={() => handleShowSubjectoInfo(Number(s.id), Number(s.idCurso))}
              >
                <strong>{s.title.includes("-") ? s.title.split("-")[0] + " - " : s.title}</strong>
                {s.title.includes("-") ? s.title.split("-")[1] : ""}
              </h2>
              <p>{findProfeTitular(buscadoEnOferta?.subjectData) || "---"}</p>
              {/* <p>{JSON.stringify(s)}</p> */}
              {/* <p>{s.sections[0].subjectData.profesor ? s.sections[0].subjectData.profesor : }</p> */}
            </div>
            <div className="desktop-selected-subject-item-right">
              <BtnUnsuscribeSubject subject={s} />
            </div>
          </div>
          <div className="selected-subject-obs">
            {s.sections[0].obs && (
              <>
                <SlIcon name="exclamation-circle" style={{ paddingRight: "5px", fontSize: "24px" }}></SlIcon>
                <p>{s.sections[0].obs}</p>
              </>
            )}
            {s.sections[0].creditos > 0 && (
              <p className="subject-cant-creditos">
                Créditos: <span>{s.sections[0].creditos}</span>
              </p>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects && inscribedSubjects?.length > 0 ? (
          <>
            {renderSelectedSubjects(inscribedSubjects)}
            {totalCreditos > 0 && (
              <div className="total-creditos-abogacia">
                <p className="">Total de créditos:</p>
                <h6>{totalCreditos}</h6>
              </div>
            )}
          </>
        ) : (
          <EmptyCourses />
        )}
      </div>
    </div>
  );
};

export default DesktopSelectedSubjects;
