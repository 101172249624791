import BannerPrioridad from "../BannerPrioridad/BannerPrioridad";
import BannerConsultaPrioridad from "../BannerConsultaPrioridad/BannerConsultaPrioridad";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export default function BannersContainer() {
  const { prioridad } = useSelector(selectStudent);

  if (prioridad === null) {
    return (
      <div
        style={{ border: "1px solid #dddddd" }}
        className="bg-[#F2F2F2] rounded-md flex justify-between items-center flex-wrap gap-[15px] px-4 py-2 md:py-0 min-h-[48px] mb-2 md:mb-0"
      >
        <div className="flex items-center gap-[15px] flex-grow">
          <SlIcon className="animate-spin" name="dash-circle" color="#B7B7B7"></SlIcon>
          <p className="text-[13px] md:text-[14px] leading-none text-[#A5A3A3]">Consultando prioridad...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <BannerPrioridad />
      <BannerConsultaPrioridad />
    </>
  );
}
