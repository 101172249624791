import { desinscribirDeCurso } from "../Api/inscription";
import { useToastError } from "./useToast";
import { useAppDispatch } from "../app/store";
import { deleteUnsubProcessSectionId, setUnsubProcessSectionIds } from "../reduxSlices/subjectsSlice";

const useUnsubscribeSection = () => {
  const showError = useToastError();
  const dispatch = useAppDispatch();

  const handleBajaCurso = async (sectionId: number): Promise<boolean> => {
    try {
      dispatch(setUnsubProcessSectionIds(sectionId));
      const response = await desinscribirDeCurso(sectionId);
      if (response.status === 200 && response.data.resultado >= 0) {
        console.log("CLOUD validations: OK");
        return true;
      } else {
        dispatch(deleteUnsubProcessSectionId(sectionId));
        showError(response.data.mensaje);
        throw new Error("Request failed");
      }
    } catch (error) {
      return false;
    }
  };
  return handleBajaCurso;
};

export default useUnsubscribeSection;
