import { IOfferedSubject, IOfferedSubjectSection } from "../types";

export const filterAndOrderSubjects = (
  subjects: IOfferedSubject[],
  inscribedSubsIds: number[]
): IOfferedSubjectSection[] => {
  const sectionsList: IOfferedSubjectSection[] = [];
  subjects
    .sort((a: IOfferedSubject, b: IOfferedSubject) => {
      const yearA = a.sections[0]?.year ?? 0;
      const yearB = b.sections[0]?.year ?? 0;

      return yearA - yearB;
    })
    .forEach((subject) => {
      const newSections = subject.sections.filter(
        (section) =>
          !inscribedSubsIds.some((idInscribed) => idInscribed === section.id) //filtramos las secciones ya inscriptas
      );
      if (newSections.length > 0) {
        sectionsList.push(...newSections);
      }
    });
  return sectionsList;
};
