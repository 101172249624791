import "./UserMenu.css";
import { useSelector } from "react-redux";
import { selectStudent, setToken } from "../../reduxSlices/studentSlice";
import { useMsal } from "@azure/msal-react";

import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlDropdown from "@shoelace-style/shoelace/dist/react/dropdown";
import SlMenu from "@shoelace-style/shoelace/dist/react/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/react/menu-item";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useAppDispatch } from "../../app/store";
import { copyTextToClipboard } from "../../utils";
import { useState } from "react";

export const UserMenu = () => {
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const { completeName, email } = useSelector(selectStudent);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [showCopyCheck, setShowCopyCheck] = useState(false)

  const notProductionFeature =
    process.env.REACT_APP_ENV === "Staging" ||
    process.env.REACT_APP_ENV === "Test" ||
    process.env.REACT_APP_ENV === "Localhost" ||
    process.env.REACT_APP_ENV === "Dev";

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(setToken(""));
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  const handleCopyText = (text:string)=>{
    setShowCopyCheck(true)
    copyTextToClipboard(text);
    setTimeout(() => {
      setShowCopyCheck(false)
    }, 1000);
  }

  return (
    <div className="header-info-data-user">
      {notProductionFeature && (
        <div
          className="hidden lg:flex gap-4 items-center bg-[#eee] px-4 py-1 rounded-full"
          title="Herramienta exclusiva para desarrollo o testing"
        >
          <SlIcon slot="suffix" name="tools" className="bg-[#edeb8a] p-2 rounded-full text-[14px]"></SlIcon>
          <div className="flex flex-col">
            <p className="text-[#656464] text-sm">
              {completeName.lastName ? completeName.lastName + ", " + completeName.name : ""}
            </p>
            <p
              className="txt-black text-[10px] font-bold hover:underline cursor-pointer flex gap-2 items-center"
              onClick={() => handleCopyText(email)}
              title="Click para copiar"
            >
              <SlIcon slot="suffix" name="copy"></SlIcon> {email}{" "}
              {showCopyCheck && (
                <span className="absolute bg-white px-2 py-1 border-[1px] border-black">Email copiado ✅</span>
              )}
            </p>
          </div>
        </div>
      )}
      <p className="header-info-data-user-name-circle">{completeName.name.charAt(0)}</p>
      <SlDropdown>
        <SlIconButton slot="trigger" className="list" name="chevron-down" label="Menu"></SlIconButton>
        <SlMenu style={{ width: "180px" }}>
          <SlMenuItem value="logout" onClick={handleLogout}>
            <span className="menu-item">Cerrar sesion</span>
            <SlIcon slot="suffix" name="box-arrow-right"></SlIcon>
          </SlMenuItem>
        </SlMenu>
      </SlDropdown>
    </div>
  );
};
