import { useEffect } from "react";
import DesktopOfferList from "../DesktopOfferList/DesktopOfferList";
import DesktopSelectedSubjects from "../DesktopSelectedSubjects/DesktopSelectedSubjects";
import "./DesktopMain.css";
import { useSelector } from "react-redux";
import {
  addSubProcessSectionIds,
  removeSubProcessSectionIds,
  selectInscribedSubjects,
} from "../../reduxSlices/subjectsSlice";
import { selectStudent } from "../../reduxSlices/studentSlice";
import FlexCalendar from "../FlexCalendar/FlexCalendar";
import { useAppDispatch } from "../../app/store";
import {
  selectFlags,
  setConfirmChangeSectionData,
  setDesktopTab,
  setSectionToChange,
} from "../../reduxSlices/globalFlagsSlice";
import Modal from "../Modal/Modal";
import { IInscribedSubject } from "../../types";
import { useToastError } from "../../hooks/useToast";
import { changeSection } from "../../Api/reservation";
import BannersContainer from "../BannersContainer/BannersContainer";
import OfferVirtualListContainer from "../OfferVirtualListContainer/OfferVirtualListContainer";

function DesktopMain() {
  const dispatch = useAppDispatch();
  const showError = useToastError();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const student = useSelector(selectStudent);
  const { desktopTab, sectionToChange, confirmChangeSectionData } = useSelector(selectFlags);

  const sectionToMantaine = (idMateria: number) => {
    return inscribedSubjects?.find((sub: IInscribedSubject) => sub.id === idMateria)?.title || "---";
  };

  useEffect(() => {
    if (student.mostrarHorarios) {
      dispatch(setDesktopTab("calendario"));
    } else {
      dispatch(setDesktopTab("materias"));
    }
  }, [student.mostrarHorarios]);

  const handleDesktopTab = (tab: "calendario" | "materias") => {
    dispatch(setDesktopTab(tab));
  };

  const handlTryChangeSection = async (idCurso: number) => {
    // setOpenModal(false);
    dispatch(addSubProcessSectionIds(idCurso));
    dispatch(setSectionToChange(null));

    const result = await changeSection({
      //@ts-ignore
      tic: confirmChangeSectionData.reserveData.tic,
      //@ts-ignore
      tim: confirmChangeSectionData.reserveData.tim,
      //@ts-ignore
      idC: confirmChangeSectionData.reserveData.idC,
      //@ts-ignore
      idA: confirmChangeSectionData.reserveData.idA,
      //@ts-ignore
      idS: confirmChangeSectionData.reserveData.idS,
    });

    if (result.data.responseCode !== 200) {
      showError(result.data.responseMessage);
      dispatch(removeSubProcessSectionIds(idCurso));
    }
    // finally
    dispatch(setConfirmChangeSectionData(null));
  };

  return (
    <div className="desktop-main">
      <div className="desktop-left">
        <h2 className="inscripciones-title">Inscripciones</h2>
        <div className="inner-left-box">
          <div className="desktop-inscribed-calendar-tab">
            <button
              className={`desktop-left-tab ${desktopTab === "materias" ? "desktop-left-tab-active" : false}`}
              onClick={() => handleDesktopTab("materias")}
            >
              Mis cursos
              <p className="desktop-inscribed-quantity">{!inscribedSubjects ? 0 : inscribedSubjects.length}</p>
            </button>
            {student.mostrarHorarios && (
              <button
                className={`desktop-left-tab ${desktopTab === "calendario" ? "desktop-left-tab-active" : false}`}
                onClick={() => handleDesktopTab("calendario")}
              >
                Calendario
              </button>
            )}
          </div>
          {desktopTab === "materias" && <DesktopSelectedSubjects />}
          {desktopTab === "calendario" && student.mostrarHorarios && <FlexCalendar />}
        </div>
      </div>
      <div className="desktop-right">
        <BannersContainer />
        {/* <DesktopOfferList /> */}
        <OfferVirtualListContainer/>
      </div>
      {sectionToChange?.id && (
        <Modal
          open={true}
          onClose={() => dispatch(setSectionToChange(null))}
          message=""
          messageHtml={<p style={{fontWeight:"400"}}>En caso de que el cambio NO sea posible, tu cupo original en <strong>{sectionToMantaine(
            sectionToChange.idMateria
          )}</strong> será reservado. De esta forma, no perderás tu cupo para cursar la materia.</p>}
          topBorderWarn={false}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => dispatch(setSectionToChange(null)),
            color: "#00C4B3",
          }}
          okButton={{
            label: "Continuar",
            buttonFn: async () => {
              await handlTryChangeSection(sectionToChange.id);
            },
            color: "#00C4B3",
          }}
        />
      )}
    </div>
  );
}

export default DesktopMain;
