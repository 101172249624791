import { ICalendarSubjects, ISubjectColors } from "../types";

export const getInitialHour = (data: any[]) => {
  return data.reduce((acc, e) => {
    return e.desde < acc ? e.desde : acc;
  }, Infinity);
};

export const getFinalHour = (data: any[]) => {
  return data.reduce((acc, e) => {
    return e.hasta > acc ? e.hasta : acc;
  }, 0);
};

export const getRowIndex = (time: number, initialTime: number) => {
  const hour = Math.floor(time / 100);
  const minute = time % 100;
  const initialHour = Math.floor(initialTime / 100);
  const totalMinutes = (hour - initialHour) * 60 + minute;
  return totalMinutes / 5 + 1;
};

export const getGridColumn = (dia: string) => {
  switch (dia) {
    case "Lunes":
      return 2;
    case "Martes":
      return 3;
    case "Miércoles":
      return 4;
    case "Jueves":
      return 5;
    case "Viernes":
      return 6;
    default:
      return 2;
  }
};

const colors: ISubjectColors[] = [
  { background: "#2277DA", border: "#2277DA" },
  { background: "#FF4789", border: "#FF4789" },
  { background: "#FF7A30", border: "#FF7A30" },
  { background: "#FBC54F", border: "#FBC54F" },
  { background: "#97D86C", border: "#97D86C" },
  { background: "#2ED4C6", border: "#2ED4C6" },
  { background: "#C47DDD", border: "#C47DDD" },
];

const colorMap = new Map();

export const assignColorToSubject = (idMateria: number) => {
  if (!colorMap.has(idMateria)) {
    const colorIndex = colorMap.size % colors.length;
    colorMap.set(idMateria, colors[colorIndex]);
  }

  return colorMap.get(idMateria);
};

export const checkIfTimeOverlaps = (
  initial1: number,
  end1: number,
  initial2: number,
  end2: number
) => {
  return initial1 < end2 && initial2 < end1
};

// Agrupamos los cursos por día y que sean superpuestos
export function calcularIndiceZ(cursos:ICalendarSubjects[]) {

 const agrupadosPorDia: { [key: string]: ICalendarSubjects[] } = cursos.reduce(
   (acumulador:any, curso:ICalendarSubjects) => {
     // Normalizamos la clave del día para evitar inconsistencias
     const diaNormalizado = curso.dia.trim().toLowerCase();

     // Si no existe la clave, inicializamos el array
     if (!acumulador[diaNormalizado]) {
       acumulador[diaNormalizado] = [];
     }

     // Agregamos el curso al array correspondiente
     acumulador[diaNormalizado].push(curso);

     return acumulador;
   },
   {} // Valor inicial del acumulador
 );

  // Iteramos sobre cada grupo por día
  Object.values(agrupadosPorDia).forEach((grupo:any) => {
    
    // Ordenamos por duración (de menor a mayor)
    grupo.sort((a:ICalendarSubjects, b:ICalendarSubjects) => (a.hasta-a.desde) - (b.hasta-b.desde));

    // Asignamos índices z en escala de 0 (más larga) a 1 (más corta)
    grupo.forEach((curso:ICalendarSubjects, index:number) => {
      curso["indiceZ"] = grupo.length - 1 - index;
    });
  });

  // Retornamos los cursos con los índices agregados
  return cursos;
}

