import { useSelector } from "react-redux";
import { deleteUnsubProcessSectionId, selectUnsubProcessSectionIds } from "../../reduxSlices/subjectsSlice";
import { LiaSpinnerSolid } from "react-icons/lia";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useState } from "react";
import Modal from "../Modal/Modal";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";

interface IProps {
  subject: any;
}

const formatearSeccionNombreTarjeta = (text: string) => {
  if (text && text.includes(" (")) {
    const parts = text.split(" (");
    const result = parts[0].trim();
    return result.replace(" - S", " S");
  } else {
    return text;
  }
};

export default function BtnUnsuscribeSubject({ subject }: IProps) {
  const unsubscribeSection = useUnsubscribeSection();
  const unsubProcessSectionIds = useSelector(selectUnsubProcessSectionIds);
  const [open, setOpen] = useState(false);

  const isProcessing = unsubProcessSectionIds.indexOf(Number(subject.idCurso)) !== -1;

  const handleTrashSubject = async (idCurso: number) => {
    setOpen(false);
    await unsubscribeSection(idCurso);
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="desktop-btn-unsuscribe-subject" disabled={isProcessing}>
        {isProcessing ? (
          <>
            <LiaSpinnerSolid className="spinner-animation" /> <span className="mobile-no-text">Procesando</span>
          </>
        ) : (
          <>
            <SlIcon name="dash-lg" />
            <span className="mobile-no-text">Desinscribirme</span>
          </>
        )}
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        message={`¿Estás seguro que querés desinscribirte de "${formatearSeccionNombreTarjeta(subject.title)}"?`}
        subMessage="Una vez que te hayas desinscripto perderás tu cupo para cursar dicha materia, esta acción no puede revertirse."
        topBorderWarn={true}
        closeButton={{
          label: "Cancelar",
          buttonFn: () => setOpen(false),
          color: "#F5A800",
        }}
        okButton={{
          label: "Si, estoy seguro",
          buttonFn: () => {
            handleTrashSubject(subject.idCurso);
          },
          color: "#F5A800",
        }}
      />
    </>
  );
}
