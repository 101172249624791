import { useEffect, useState } from "react";
import "./DesktopSubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import {
  ICalendarSubjects,
  IDesktopCalendarSubjects,
  IOfferedReserveData,
  IOfferedSubjectData,
  IQuota,
  ISelectedSection,
} from "../../types";
import { useSelector } from "react-redux";
import { BsFillPinAngleFill } from "react-icons/bs";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectSubcriptionProcessSectionIds,
  setDetailSubjectOffered,
  setPinnedSubjectsList,
  addSubProcessSectionIds,
  subtractCalendarSubjectDemoCount,
  removeSubProcessSectionIds,
  selectFailedSubcriptionIds,
} from "../../reduxSlices/subjectsSlice";
import { useAppDispatch } from "../../app/store";
import {
  selectFlags,
  selectInTransitInRquest,
  selectMobileFeatures,
  setDesktopTab,
  setLastCalendarAdd,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import { useNavigate } from "react-router-dom";
import QuotaCountIcon from "../QuotaCountIcon/QuotaCountIcon";
import ButtonInscription from "../ButtonInscription/ButtonInscription";
import { findProfeTitular } from "../../utils";

interface IPropsSubjectItem {
  id: number;
  subjectId: number;
  section: string;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange?: (data: ISelectedSection) => Promise<any>;
  pinned: boolean;
  creditos: number;
  sectionType: string;
  idMateria: number;
  turno: string;
}

const filtrarCasosRepetidos = (cursos: any[]) => {
  let uniqueCourses: any = [];
  cursos.forEach((curso: any) => {
    const existe = uniqueCourses.some(
      (uniqueCurso: any) => uniqueCurso.dia === curso.dia && uniqueCurso.desde === curso.desde
    );
    if (!existe) {
      uniqueCourses.push(curso);
    }
  });
  return uniqueCourses;
};

function extraerSeccion(texto: string) {
  const regex = /- (S\d+)/;
  const match = texto.match(regex);
  return match ? match[1] : null;
}
const extraerNombreMateria = (texto: string) => {
  return texto.split(" - ")[0] || "texto";
};

const DesktopSubjectItem = ({
  section,
  id,
  subjectData,
  quota,
  onChange,
  subjectId,
  pinned,
  creditos,
  sectionType,
  idMateria,
  turno,
}: IPropsSubjectItem) => {
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const { collapseCards, collapseCardsSinCupo } = useSelector(selectFlags);
  const dispatch = useAppDispatch();
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const [inscriptionConfirmed, setInscriptionConfirmed] = useState(false);
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);
  const failedSubcriptionIds = useSelector(selectFailedSubcriptionIds);
  const [failedInscription, setFailedInscription] = useState(false);
  const [isSubInProcess, setIsSubInProcess] = useState(false);

  const navigate = useNavigate();

  const subType = sectionType === "OPTATIVO" ? "Optativa" : "Obligatoria";

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setTimeout(() => {
        setHasClick(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* ------------------- Failed in validation response CLOUD ------------------ */
  useEffect(() => {
    if (failedInscription) {
      setTimeout(() => {
        setFailedInscription(false);
      }, 500);
    }
  }, [failedInscription]);
  /* ---------------------- Failed in response Web Socket --------------------- */
  useEffect(() => {
    if (failedSubcriptionIds.indexOf(Number(id)) !== -1) {
      setFailedInscription(true);
    }
  }, [failedSubcriptionIds]);

  useEffect(() => {
    const isProcessing = subcriptionProcessSectionIds.indexOf(Number(id)) !== -1;
    if (isProcessing) {
      setIsSubInProcess(true);
    } else {
      setIsSubInProcess(false);
    }
  }, [subcriptionProcessSectionIds]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };

  const handleToggleCalendarSubject = async (idCurso: number, reserveData: IOfferedReserveData) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: subjectId,
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
          tipoCursada: horario.values.tipoClase,
        };

        listado.push(newDemo);
      });
    });
    listado = filtrarCasosRepetidos(listado);
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));

    // creado para hacer el scroll into view de la tarjeta
    dispatch(setLastCalendarAdd(String(idCurso)));
    // if MobileCalendario, go to calendar
    if (mobileFeatures) {
      navigate("/calendario");
    } else {
      dispatch(setDesktopTab("calendario"));
    }
  };

  const handleModalPanelInfo = (subjectId: number, cursoId: number) => {
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
    dispatch(setPanelFilters(false));
  };

  return (
    <div className="desktop-subject-item-container">
      <div
        className={`desktop-subject-item ${pinned ? "desktop-pinned-subject" : ""} ${
          quota && quota.availability === 0 && student.prioridad ? "bg-gray-noquota" : ""
        } ${inscriptionConfirmed ? "hidden-card" : ""}`}
      >
        <div className={`animation-wrapper ${failedInscription ? "cancel-animation" : ""}`}>
          <div className={`${isSubInProcess ? "fill-animation" : " "}`}></div>
          <div style={{ zIndex: 1 }} className="desktop-item-first-row">
            {quota && quota?.availability >= 0 && student.prioridad && (
              <>
                <QuotaCountIcon
                  qty={quota.qty}
                  subjectData={subjectData}
                  availability={quota.availability}
                  idMateria={idMateria}
                  quota={quota}
                />
              </>
            )}
            <div className="title-column-card w-3/6">
              {mobileFeatures ? (
                <div
                  className={`flex flex-row hover:underline hover:cursor-pointer ${
                    quota && quota.availability === 0 && student.prioridad ? "text-gray" : ""
                  }`}
                  onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
                >
                  <p
                    title={`${extraerNombreMateria(section)}`}
                    className={`desktop-item-title desktop-item-section max-w-fit`}
                  >
                    {`${extraerNombreMateria(section)} `}
                  </p>
                  <p className={`desktop-item-title max-w-fit`}>&nbsp;{`${extraerSeccion(section)}`}</p>
                </div>
              ) : (
                <div
                  className={`flex flex-row w-auto hover:underline hover:cursor-pointer ${
                    quota && quota.availability === 0 && student.prioridad ? "text-gray" : ""
                  }`}
                  onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
                >
                  <p title={`${extraerNombreMateria(section)}`} className={`desktop-item-title desktop-item-section`}>
                    {`${extraerNombreMateria(section)}`}
                  </p>
                  <p className={`desktop-item-title min-w-fit`}>&nbsp;{`${extraerSeccion(section)}`}</p>
                </div>
              )}
              <p className="mobile-profesor-titular">{findProfeTitular(subjectData) || "A designar"}</p>
            </div>
            <div className="desktop-items-actions">
              <div className="item-actions-circles">
                {pinned && <p className="subject-item-type">{subType}</p>}
                <button
                  className="desktop-action btn-pin"
                  title="Fijar materia"
                  onClick={() => handlePinSubject(id, pinned)}
                >
                  <BsFillPinAngleFill />
                </button>
                {student.mostrarHorarios && (
                  <button
                    className={`desktop-action ${
                      calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id)
                        ? "btn-calendar-active"
                        : ""
                    } ${calendarLoading ? "calendar-loading-icon" : ""}`}
                    title="Visualizar en calendario"
                    // onClick={() => handleToggleCalendarSubject(id)}
                    onClick={() => handleToggleCalendarSubject(Number(id), subjectData.reserveData)}
                    disabled={calendarLoading}
                  >
                    <SlIcon name="calendar"></SlIcon>
                  </button>
                )}
              </div>
              {student.prioridad && quota && quota.availability === 0 && (
                <button className="desktop-action-add-noquota" disabled>
                  <SlIcon name="x"></SlIcon>
                  Sin cupo
                </button>
              )}
              {student.prioridad && quota && quota.availability > 0 && (
                <ButtonInscription id={id} idMateria={idMateria} subjectData={subjectData} />
              )}
            </div>
          </div>
          <div
            className={`${
              collapseCards
                ? "hide-collapse-info"
                : collapseCardsSinCupo && quota && quota.availability === 0
                ? "hide-collapse-info"
                : ""
            } bottom-car-section`}
          >
            <div className="divider-card"></div>
            <div className={`desktop-item-second-row ${mobileFeatures ? "max-h-28 overflow-y-auto" : ""}`}>
              <ProfessorAndSubjectHours subjectData={subjectData} isDrawerContent={false} />
              {turno ? (
                <div className="desktop-inner-col" style={{ width: "100px", zIndex: "1" }}>
                  <h4 className="ph-h4-title">Turno</h4>
                  <p>{turno}</p>
                </div>
              ) : (
                <div className="desktop-inner-col"></div>
              )}
              {creditos > 0 ? (
                <div className="desktop-inner-col" style={{ zIndex: "1" }}>
                  <h4 className="ph-h4-title">Créditos</h4>
                  <p>{creditos}</p>
                </div>
              ) : (
                <div className="desktop-inner-col"></div>
              )}
            </div>
          </div>
          <div className="mobile-items-actions" style={{ zIndex: 1 }}>
            {pinned && <p className="subject-item-type">{subType}</p>}
            <button
              className="desktop-action btn-pin"
              title="Fijar materia"
              onClick={() => handlePinSubject(id, pinned)}
            >
              <BsFillPinAngleFill />
            </button>
            {student.mostrarHorarios && (
              <button
                style={{ marginRight: "4px" }}
                className={`desktop-action ${
                  calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id)
                    ? "btn-calendar-active"
                    : ""
                } ${calendarLoading ? "calendar-loading-icon" : ""}`}
                title="Visualizar en calendario"
                // onClick={() => handleToggleCalendarSubject(id)}
                onClick={() => handleToggleCalendarSubject(Number(id), subjectData.reserveData)}
                disabled={calendarLoading}
              >
                <SlIcon name="calendar"></SlIcon>
              </button>
            )}
            {student.prioridad && quota && quota.availability === 0 && (
              <button className="desktop-action-add-noquota" disabled>
                <SlIcon name="x"></SlIcon>
                Sin cupo
              </button>
            )}
            {student.prioridad && quota && quota.availability > 0 && (
              <ButtonInscription id={id} idMateria={idMateria} subjectData={subjectData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSubjectItem;
